@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
}

body {
  font-family: 'Avenir';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  border-radius: 0px !important;
  background-color: rgb(229 231 235) !important;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: rgb(100, 100, 100) !important;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: black !important;
}

.marker-cluster span {
  color: white !important;
}

.leaflet-container a.leaflet-popup-close-button {
  width: 32px !important;
  height: 32px !important;
  font-size: 24px !important;
}

/* fix blue color when button clicked on chrome  */
:focus {
  outline: none !important;
}
